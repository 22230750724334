import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import { BeatLoader } from  'react-spinners'
import {CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { Input, Button, message} from 'antd';
import queryString from "query-string";
import axios from "axios";

const Lazada = () => {
    const [waiting, setWaiting] = useState(true)
    const [isSuccess, setIsSuccess] = useState(true)
    const [manualInput, setManualInput] = useState(false)
    const [closeableWindow, setCloseableWindow] = useState(false)
    const [authString, setAuthString] = useState(''); // 新状态用于存储编码后的授权码
    const location = useLocation()
    const queryParams = queryString.parse(location.search);

    const { TextArea } = Input;

    useEffect(()=>{
        const code = queryParams.code
        const state = queryParams.state
        const authData = {
            auth_code: code,  // 将 'code' 改名为 'auth_code'
            state: state      // 保持 'state' 名称不变
        };
        const jsonString = JSON.stringify(authData);
        const encodedAuth = btoa(jsonString);
        setAuthString(encodedAuth);
        axios.post('https://api.workpled.com/api/setting/addNewStore', authData, {timeout: 3000 }).then(
            response => {
                if (response.data.success) {
                    setWaiting(false)
                    setIsSuccess(true)
                    if (window.opener) {
                        setCloseableWindow(true)
                        setTimeout(()=>{
                            window.close()
                        }, 3000)
                    } else {
                        setCloseableWindow(false)
                    }

                } else {
                    setWaiting(false)
                    setIsSuccess(false)
                }
            },
            error => {
                setWaiting(false)
                setIsSuccess(false)
                setManualInput(true)
            }
        )
    },[location.search])

    const titleStyle = {
        marginTop: '10px',
        marginBottom: '0px',
        color: "rgba(0, 0, 0, 0.88)",
        fontSize: "24px",
        lineHeight: 1.3333333333333333,
    }

    const Success = ()=>{
        return(
            <div>
                <CheckCircleOutlined style={{ fontSize: '48px', color: '#52c41a'}} />
                <div style={titleStyle}>授权成功</div>
                <div style={{
                    color: "rgba(0, 0, 0, 0.45)",
                    fontSize: "14px",
                    lineHeight: 1.57,
                }}>{closeableWindow?'3秒后自动关闭页面':'请关闭本页面'}</div>
            </div>
        )
    }
    const Failed = ()=>{
        return(
            <div>
                <CloseCircleOutlined style={{ fontSize: '48px', color: '#ff4d4f'}} />
                <div style={titleStyle}>授权失败</div>
                <div style={{
                    color: "rgba(0, 0, 0, 0.45)",
                    fontSize: "14px",
                    lineHeight: 1.57,
                }}>请关闭页面重新发起授权</div>
            </div>
        )
    }

    const Manual = () => {
        const titleStyle = {
            marginTop: '10px',
            marginBottom: '0px',
            color: "rgba(0, 0, 0, 0.88)",
            fontSize: "24px",
            lineHeight: 1.3333333333333333,
        };

        const handleCopy = () => {
            // 使用 navigator.clipboard API 复制文本
            navigator.clipboard.writeText(authString).then(() => {
                message.success('已复制');
            }).catch(err => {
                console.error('Failed to copy: ', err);
                message.error('复制失败');
            });
        };

        return (
            <div style={{ position: 'relative', width: '350px', margin: 'auto' }}>
                <InfoCircleOutlined style={{ fontSize: '48px', color: '#faad14'}} />
                <div style={titleStyle}>请手动输入授权码</div>
                <TextArea
                    autoSize={{
                        minRows: 5,
                        maxRows: 5
                    }}
                    style={{
                        marginTop: '30px',
                        width: '100%', // 使 TextArea 宽度充满容器
                    }}
                    // 假设 authString 是你定义的状态
                    value={authString}
                />
                <Button
                    type="primary"
                    shape="circle"
                    icon={<CopyOutlined />}
                    style={{
                        position: 'absolute',
                        right: '10px', // 右边距
                        bottom: '10px', // 底部边距
                    }}
                    onClick={handleCopy}
                />
            </div>
        );
    };

    const Auth = ()=>{
        return (
            <div style={{marginTop: '40px'}}>
                <BeatLoader
                    size={20}
                    color="#91caff"
                />
                <div style={{...titleStyle, marginTop: '20px'}} >授权中</div>
            </div>
        )
    }

    return (
        <div style={{textAlign: 'center', marginTop: '30px'}}>
            {waiting?<Auth/>:(isSuccess?<Success/>:(manualInput?<Manual/>:<Failed/>))}
        </div>
    );
};

export default Lazada;