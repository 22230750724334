import Lazada from "../pages/lazada";
import Tiktok from "../pages/tiktok";
import NotFoundPage from "../pages/404";
import Shopee from "../pages/shopee";


const routes = [
    {
        path:'/lazada',
        element:<Lazada/>,
    },
    {
        path:'/tiktok',
        element:<Tiktok/>,
    },
    {
        path:'/shopee/:state',
        element:<Shopee/>,
    },
    {
        path: '*',
        element: <NotFoundPage/>
    }
]
export default routes;